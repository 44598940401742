import { Typography, Box} from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import GoodspeedGif from '../../Assets/goodspeed.gif';
import './styles.css';

const Losses = (props) => {
    return (
        <div className="Page">
            <Box className="Losses">
                <Typography variant="h5" className="Heading"><b>Losses</b></Typography>
                    <Box className="Loss">
                    <Card className="Card">
                    <CardHeader title="Kupp The Balls " className="CardHeader"/>
                        <CardContent>
                            <Typography variant="body1" className="CardText">
                                The pain may be temporary, but a loss to Goodspeed lasts forever.
                            </Typography>
                            <img className="GoodspeedGif" src={GoodspeedGif} alt="WrestlingGif"/>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </div>
    )
}

export default Losses