import Research from './Components/Research';
import SEO from './Components/SEO';
import WelcomeModal from './Components/WelcomeModal';
import Navbar from './Components/Navbar';
import Navigation from './Components/Navigation';
import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';

function App() {

  const [pointlessVar] = useState(false);
  const [hasVisited, setHasVisited] = useState(false);

  useEffect(() => {
    // for testing window.sessionStorage.setItem("visited", false);
    var visited = window.sessionStorage.getItem("visited");
    setHasVisited(visited);
  }, [])

  return (
    <Fragment>
      {pointlessVar
      ?
      <Fragment>
        <Research />
        <SEO />
        </Fragment>
      :
      <Fragment>
        <Navbar />
        <Navigation />
      </Fragment>
      }
    </Fragment>
  );
}

export default App;
