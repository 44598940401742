import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#254D32', //dc1c13, 1c3144
    }
  },
  components:{
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#254D32'
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          color: '#254D32'
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#C6DABF',
          color: '#ffffff'
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
        
        },
      },
    },
    MuiCardACtions: {
      styleOverrides: {
        root: {
          padding: '16px !important'
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          '&.Mui-selected': {
            color: '#ffffff',
            borderBottom: '4px solid #edf2ff'
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#254D32',
          height: '100%'
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        },
      },
    },
  }
});

export default theme