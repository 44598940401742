import React from 'react'

const Research = () => {
  return (
    <div>
        Background: Dynamic contrast-enhanced (DCE) MRI is widely used to assess vascular perfusion and permeability in cancer. 
        In small animal applications, conventional modeling of pharmacokinetic (PK) 
        parameters from DCE MRI images is complex and time consuming. This study is aimed at developing a deep learning approach 
        to fully automate the generation of kinetic parameter maps, Ktrans (volume transfer coefficient) and Vp (blood plasma volume ratio), 
        as a potential surrogate to conventional PK modeling in mouse brain tumor models based on DCE MRI. Methods: Using a 7T MRI, DCE MRI 
        was conducted in U87 glioma xenografts growing orthotopically in nude mice. Vascular permeability Ktrans and Vp maps were generated 
        using the classical Tofts model as well as the extended-Tofts model. These vascular permeability maps were then processed as target 
        images to a twenty-four layer convolutional neural network (CNN). The CNN was trained on T1-weighted DCE images as source images and 
        designed with parallel dual pathways to capture multiscale features. Furthermore, we performed a transfer study of this glioma trained C
        NN on a breast cancer brain metastasis (BCBM) mouse model to assess the potential of the network for alternative brain tumors. Results: 
        Our data showed a good match for both Ktrans and Vp maps generated between the target PK parameter maps and the respective CNN maps for 
        gliomas. Pixel-by-pixel analysis revealed intratumoral heterogeneous permeability, which was consistent between the CNN and PK models. 
        The utility of the deep learning approach was further demonstrated in the transfer study of BCBM. Conclusions: Because of its rapid and 
        accurate estimation of vascular PK parameters directly from the DCE dynamic images without complex mathematical modeling, the deep 
        learning approach can serve as an efficient tool to assess tumor vascular permeability to facilitate small animal brain tumor research.
        Background: Dynamic contrast-enhanced (DCE) MRI is widely used to assess vascular perfusion and permeability in cancer. 
        In small animal applications, conventional modeling of pharmacokinetic (PK) 
        parameters from DCE MRI images is complex and time consuming. This study is aimed at developing a deep learning approach 
        to fully automate the generation of kinetic parameter maps, Ktrans (volume transfer coefficient) and Vp (blood plasma volume ratio), 
        as a potential surrogate to conventional PK modeling in mouse brain tumor models based on DCE MRI. Methods: Using a 7T MRI, DCE MRI 
        was conducted in U87 glioma xenografts growing orthotopically in nude mice. Vascular permeability Ktrans and Vp maps were generated 
        using the classical Tofts model as well as the extended-Tofts model. These vascular permeability maps were then processed as target 
        images to a twenty-four layer convolutional neural network (CNN). The CNN was trained on T1-weighted DCE images as source images and 
        designed with parallel dual pathways to capture multiscale features. Furthermore, we performed a transfer study of this glioma trained C
        NN on a breast cancer brain metastasis (BCBM) mouse model to assess the potential of the network for alternative brain tumors. Results: 
        Our data showed a good match for both Ktrans and Vp maps generated between the target PK parameter maps and the respective CNN maps for 
        gliomas. Pixel-by-pixel analysis revealed intratumoral heterogeneous permeability, which was consistent between the CNN and PK models. 
        The utility of the deep learning approach was further demonstrated in the transfer study of BCBM. Conclusions: Because of its rapid and 
        accurate estimation of vascular PK parameters directly from the DCE dynamic images without complex mathematical modeling, the deep 
        learning approach can serve as an efficient tool to assess tumor vascular permeability to facilitate small animal brain tumor research.
        Background: Dynamic contrast-enhanced (DCE) MRI is widely used to assess vascular perfusion and permeability in cancer. 
        In small animal applications, conventional modeling of pharmacokinetic (PK) 
        parameters from DCE MRI images is complex and time consuming. This study is aimed at developing a deep learning approach 
        to fully automate the generation of kinetic parameter maps, Ktrans (volume transfer coefficient) and Vp (blood plasma volume ratio), 
        as a potential surrogate to conventional PK modeling in mouse brain tumor models based on DCE MRI. Methods: Using a 7T MRI, DCE MRI 
        was conducted in U87 glioma xenografts growing orthotopically in nude mice. Vascular permeability Ktrans and Vp maps were generated 
        using the classical Tofts model as well as the extended-Tofts model. These vascular permeability maps were then processed as target 
        images to a twenty-four layer convolutional neural network (CNN). The CNN was trained on T1-weighted DCE images as source images and 
        designed with parallel dual pathways to capture multiscale features. Furthermore, we performed a transfer study of this glioma trained C
        NN on a breast cancer brain metastasis (BCBM) mouse model to assess the potential of the network for alternative brain tumors. Results: 
        Our data showed a good match for both Ktrans and Vp maps generated between the target PK parameter maps and the respective CNN maps for 
        gliomas. Pixel-by-pixel analysis revealed intratumoral heterogeneous permeability, which was consistent between the CNN and PK models. 
        The utility of the deep learning approach was further demonstrated in the transfer study of BCBM. Conclusions: Because of its rapid and 
        accurate estimation of vascular PK parameters directly from the DCE dynamic images without complex mathematical modeling, the deep 
        learning approach can serve as an efficient tool to assess tumor vascular permeability to facilitate small animal brain tumor research.
        Background: Dynamic contrast-enhanced (DCE) MRI is widely used to assess vascular perfusion and permeability in cancer. 
        In small animal applications, conventional modeling of pharmacokinetic (PK) 
        parameters from DCE MRI images is complex and time consuming. This study is aimed at developing a deep learning approach 
        to fully automate the generation of kinetic parameter maps, Ktrans (volume transfer coefficient) and Vp (blood plasma volume ratio), 
        as a potential surrogate to conventional PK modeling in mouse brain tumor models based on DCE MRI. Methods: Using a 7T MRI, DCE MRI 
        was conducted in U87 glioma xenografts growing orthotopically in nude mice. Vascular permeability Ktrans and Vp maps were generated 
        using the classical Tofts model as well as the extended-Tofts model. These vascular permeability maps were then processed as target 
        images to a twenty-four layer convolutional neural network (CNN). The CNN was trained on T1-weighted DCE images as source images and 
        designed with parallel dual pathways to capture multiscale features. Furthermore, we performed a transfer study of this glioma trained C
        NN on a breast cancer brain metastasis (BCBM) mouse model to assess the potential of the network for alternative brain tumors. Results: 
        Our data showed a good match for both Ktrans and Vp maps generated between the target PK parameter maps and the respective CNN maps for 
        gliomas. Pixel-by-pixel analysis revealed intratumoral heterogeneous permeability, which was consistent between the CNN and PK models. 
        The utility of the deep learning approach was further demonstrated in the transfer study of BCBM. Conclusions: Because of its rapid and 
        accurate estimation of vascular PK parameters directly from the DCE dynamic images without complex mathematical modeling, the deep 
        learning approach can serve as an efficient tool to assess tumor vascular permeability to facilitate small animal brain tumor research.
    </div>
  )
}

export default Research