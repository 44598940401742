import React, {useState, useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Losses from '../../Pages/Losses'
import Results from '../../Pages/Results'

const Navigation= () => { 
    const [page, setPage] = useState("0");

    useEffect(() => {
        var currentTab = window.sessionStorage.getItem("page")
        if(currentTab){
            setPage(currentTab)
        }
    }, [page])
    
    const handleChange = (event, page) => {
        window.sessionStorage.setItem("page", page);
        setPage(page);
      };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
            <TabContext value={page}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} 
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            aria-label="scrollable force tabs example">
                        <Tab label="Losses" value="0"/>
                        <Tab label="Results" value="1"/>
                    </TabList>
                </Box>
                <TabPanel value="0">
                    <Losses />
                </TabPanel>
                <TabPanel value="1">
                    <Results/>
                </TabPanel>
            </TabContext>
            </AppBar>
        </Box>
    )
}

export default Navigation
