import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import './styles.css'; 
import { Typography } from '@mui/material';

const Navbar = () => {
    return (
        <Box sx={{ flexGrow: 1, border: 0 }}>
            <AppBar sx={{ justifyContent: 'center'}} position="static">
                <Toolbar>
                    <Typography variant='h6' sx={{ fontStyle: 'italic', fontWeight: 'light'}}>CHAD BLOWS</Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navbar
