import React from 'react'

const SEO = () => {
  return (
    <div>
        Chad Arledge sucks at fantasy football. Chad Arledge. Arledge. Chad. Wake Forest. Sadge. Thoughts. Wake Forest Research.
        Chad Arledge Student Teacher. Chad Arledge Teacher Assistant. Chad Arledge Rate my Professor.
    </div>
  )
}

export default SEO