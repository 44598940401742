import { Box} from '@mui/material';
import React from 'react';
import './styles.css';

const Results = (props) => {
    return (
        <div className="Page">
            <Box className="Results">
                Under Construction
            </Box>
        </div>
    )
}

export default Results